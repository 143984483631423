/** @jsx jsx */

import { jsx, Heading, Flex, Label, Box, Select } from "theme-ui"
import {
  PageRoot,
  ContentNode,
  Connection,
  Edge,
  ContentFrontmatter,
} from "../data/content"
import {
  IndexPageContainer,
  IndexPageRows,
  IndexPageRowsCompact,
  IndexPageRowsCompactFlexible,
  MovementPageContainer,
} from "../components/indexes"
import React, { Fragment, useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Columns, Rows, ResponsiveSplit } from "../components/atoms"
import {
  FeaturedArticle,
  FeaturedList,
  FeaturedListItem,
  MovementAnnouncementBlock,
  MovementPrimaryFeatured,
  MovementPrimaryFeaturedWrapper,
  MovementSecondaryFeaturedBlock,
  useLocalisedContent,
} from "../components/featured"
import { ContentBlock, MobileContentBlock } from "../components/page"
import { useTranslation, Trans } from "react-i18next"
import SEO from "../components/seo"
import { PillarLogo } from "../images/pillars"
import { useTextDirection } from "../i18n"
import {
  FilterStyling,
  PillarFiltersStyling,
} from "../components/joinform/elements"
import { groupBy, keys } from "lodash"
import { colourMidpoint } from "../data/methods"
import languages from "../data/languages"
import { Tile, TileWrapper } from "../types/movement"

const MovementIndexPage: PageRoot<{
  actions: Connection<ContentNode>
  campaigns: Connection<ContentNode>
  tiles: Connection<ContentNode>
}> = ({ data: { actions, campaigns, tiles }, location: { pathname } }) => {
  const {
    i18n: { language },
    t,
  } = useTranslation()
  const dir = useTextDirection(language)

  const unintrusiveColour = "#6a6a6a"

  const fadedColour = colourMidpoint("#EACCFA", "#c600e2")

  const [validCampaigns, setValidCampaigns] = useState(campaigns.edges)
  const [validActions, setValidActions] = useState(actions.edges)

  const tileList: TileWrapper[] =
    tiles.edges[0].node?.frontmatter.tiles
      .map(tile => {
        if (tile.campaignArticle && typeof tile.campaignArticle !== "string") {
          return {
            isManual: false,
            tile,
          }
        } else if (
          tile.translations &&
          tile.translations.length > 0 &&
          tile.manualImage
        ) {
          return {
            isManual: true,
            tile,
          }
        }
      })
      .filter((tileWrapper: TileWrapper) => !!tileWrapper) || []

  //ARTICLES-------------------------------------------------------------------------------------------------------------
  const articles = actions.edges.filter(function(article) {
    return article.node.fields?.type !== "movement-campaigns"
  })

  const groupedArticlesByTopic = groupBy(articles, (article: any) => {
    return article.node.frontmatter.topic?.id
  })
  const groupedArticlesByRegion = groupBy(articles, (article: any) => {
    return article.node.frontmatter.region?.id
  })

  let availableAuthors: string[] = []

  articles.forEach(function(article) {
    article.node.frontmatter.authors?.forEach(function(author) {
      if (!availableAuthors.includes(author?.id)) {
        availableAuthors.push(author?.id)
      }
    })
  })

  availableAuthors.sort()

  let groupedArticlesByAuthorDictionary: {
    [id: string]: Edge<ContentNode<ContentFrontmatter>>[]
  } = {}

  availableAuthors.forEach(author => {
    groupedArticlesByAuthorDictionary[author] = []
  })

  articles.forEach(article => {
    availableAuthors.forEach(author => {
      if (
        article.node.frontmatter.authors
          ?.map(author => author?.id)
          .includes(author)
      ) {
        groupedArticlesByAuthorDictionary[author].push(article)
      }
    })
  })

  const groupedArticlesByAuthor = groupedArticlesByAuthorDictionary

  let availableLocales: string[] = []

  articles.forEach(function(article) {
    article.node.fields.localePaths?.forEach(function(localePath) {
      if (!availableLocales.includes(localePath.locale)) {
        availableLocales.push(localePath.locale)
      }
    })
  })

  availableLocales.sort()

  let groupedArticlesByLanguageDictionary: {
    [id: string]: Edge<ContentNode<ContentFrontmatter>>[]
  } = {}

  availableLocales.forEach(locale => {
    groupedArticlesByLanguageDictionary[locale] = []
  })

  articles.forEach(article => {
    availableLocales.forEach(locale => {
      if (
        article.node.fields.localePaths
          ?.map(localePath => localePath.locale)
          .includes(locale)
      ) {
        groupedArticlesByLanguageDictionary[locale].push(article)
      }
    })
  })

  const groupedArticlesByLanguage = groupedArticlesByLanguageDictionary

  //CAMPAIGNS-------------------------------------------------------------------------------------------------------------
  const groupedCampaignsByTopic = groupBy(campaigns.edges, (campaign: any) => {
    return campaign.node.frontmatter.topic?.id
  })
  const groupedCampaignsByRegion = groupBy(campaigns.edges, (campaign: any) => {
    return campaign.node.frontmatter.region?.id
  })

  campaigns.edges.forEach(function(campaign: any) {
    campaign.node.fields.localePaths?.forEach(function(localePath: any) {
      if (!availableLocales.includes(localePath.locale)) {
        availableLocales.push(localePath.locale)
      }
    })
  })

  availableLocales.sort()

  let groupedCampaignsByLanguageDictionary: {
    [id: string]: Edge<ContentNode<ContentFrontmatter>>[]
  } = {}

  availableLocales.forEach(locale => {
    groupedCampaignsByLanguageDictionary[locale] = []
  })

  campaigns.edges.forEach(campaign => {
    availableLocales.forEach(locale => {
      if (
        campaign.node.fields.localePaths
          ?.map(localePath => localePath.locale)
          .includes(locale)
      ) {
        groupedCampaignsByLanguageDictionary[locale].push(campaign)
      }
    })
  })

  const groupedCampaignsByLanguage = groupedCampaignsByLanguageDictionary

  const groupedContentByTopic = {
    ...groupedArticlesByTopic,
    ...groupedCampaignsByTopic,
  }
  const groupedContentByRegion = {
    ...groupedArticlesByRegion,
    ...groupedCampaignsByRegion,
  }
  const groupedContentByLanguage = {
    ...groupedArticlesByLanguage,
    ...groupedCampaignsByLanguage,
  }

  const [state, setState] = useState({
    filters: {
      selectedTopicId: "",
      selectedRegionId: "",
      selectedAuthorId: "",
      selectedLocale: "",
    },
  })

  useEffect(() => {
    setValidCampaigns(
      campaigns.edges.filter(({ node }) => {
        if (showArticle(node)) {
          return true
        } else {
          return false
        }
      })
    )

    setValidActions(
      actions.edges
        .filter(({ node }) => {
          if (showArticle(node)) {
            return true
          } else {
            return false
          }
        })
        .sort((a, b) => {
          const aDate =
            a.node.frontmatter.startDate || a.node.frontmatter.publishDate
          const bDate =
            b.node.frontmatter.startDate || b.node.frontmatter.publishDate

          if (aDate && bDate) {
            return aDate > bDate ? -1 : 1
          } else {
            return -1
          }
        })
    )
  }, [state])

  const handleTopicChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...state.filters, selectedTopicId: event.target.value }
    setState({ filters })
  }

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...state.filters, selectedRegionId: event.target.value }
    setState({ filters })
  }

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const filters = { ...state.filters, selectedLocale: event.target.value }
    setState({ filters })
  }

  const handleAuthorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...state.filters, selectedAuthorId: event.target.value }
    setState({ filters })
  }

  const showArticle = (node: ContentNode) => {
    const selectedAuthorId = state.filters.selectedAuthorId
    const selectedTopicId = state.filters.selectedTopicId
    const selectedRegionId = state.filters.selectedRegionId
    const selectedLocale = state.filters.selectedLocale

    let valid = true

    try {
      if (selectedTopicId?.length) {
        if (node.frontmatter.topic?.id != selectedTopicId) {
          valid = false
        } else if (!node.frontmatter.topic) {
          valid = false
        }
      }

      if (selectedRegionId?.length) {
        if (node.frontmatter.region?.id != selectedRegionId) {
          valid = false
        } else if (!node.frontmatter.region) {
          valid = false
        }
      }

      if (selectedLocale?.length) {
        if (
          !node.fields.localePaths
            ?.map(localePath => localePath.locale)
            .includes(selectedLocale)
        ) {
          valid = false
        } else if (node.fields.localePaths === undefined) {
          valid = false
        }
      }

      if (
        selectedAuthorId?.length &&
        node.fields?.type !== "movement-campaigns"
      ) {
        if (
          !node.frontmatter.authors
            ?.map(author => author?.id)
            .includes(selectedAuthorId)
        ) {
          valid = false
        } else if (node.frontmatter.authors === undefined) {
          valid = false
        }
      }
    } catch (e) {
      valid = false
    }

    return valid
  }

  const toggleFilters = () => {
    try {
      const filterContainer = document.getElementById("filterContainer")

      if (filterContainer?.classList.contains("openFilter")) {
        filterContainer.classList.remove("openFilter")
      } else {
        filterContainer?.classList.add("openFilter")
      }
    } catch (e) {}
  }

  return (
    <MovementPageContainer
      title={t(`Movement`)}
      bg="movementHexLight"
      color="movementHex"
      isRtl={dir.isRtl}
      marginBelow={false}
      heading={
        <Fragment>
          <p>
            <Trans>
              We are connecting, supporting, and mobilising activists around the
              world.
            </Trans>
          </p>
          <p>
            <Trans>
              Each week, we share toolkits, host training, and convene forums
              that bring together members of the Progressive International.
            </Trans>
          </p>
          <p>
            <Trans>
              Become a member, and participate in the Movement pillar.
            </Trans>
          </p>
        </Fragment>
      }
      icon={
        <PillarLogo
          sx={{ color: "movementHex", size: [40, 70, 80, 100] }}
          type={"movement"}
        />
      }
      // extra={
      //   <Flex
      //     id="filterContainer"
      //     sx={PillarFiltersStyling(
      //       "movement",
      //       fadedColour,
      //       unintrusiveColour,
      //       dir
      //     )}
      //   >
      //     <Label>
      //       FILTERS
      //       <div onClick={toggleFilters}>
      //         <Select disabled></Select>
      //       </div>
      //     </Label>
      //     <Box
      //       pr={["0px", "6px", "42px"]}
      //       sx={{ flex: ["100%", "25%", "25%"] }}
      //     >
      //       <Select
      //         name="topic"
      //         onChange={handleTopicChange}
      //         sx={FilterStyling(
      //           state.filters.selectedTopicId,
      //           "movement",
      //           fadedColour,
      //           unintrusiveColour,
      //           dir,
      //           false
      //         )}
      //       >
      //         <option value="">{t("Theme")}</option>
      //         {keys(groupedContentByTopic).map(
      //           (id: string) =>
      //             id != "undefined" && (
      //               <option value={id}>
      //                 {
      //                   useLocalisedContent(
      //                     groupedContentByTopic[id][0].node.frontmatter.topic
      //                   )?.title
      //                 }
      //               </option>
      //             )
      //         )}
      //       </Select>
      //     </Box>
      //     <Box
      //       pl={["0px", "2px", "14px"]}
      //       pr={["0px", "4px", "28px"]}
      //       sx={{
      //         direction: dir.isRtl ? "rtl" : "ltr",
      //         flex: ["100%", "25%", "25%"],
      //       }}
      //     >
      //       <Select
      //         name="region"
      //         onChange={handleRegionChange}
      //         sx={FilterStyling(
      //           state.filters.selectedRegionId,
      //           "movement",
      //           fadedColour,
      //           unintrusiveColour,
      //           dir,
      //           false
      //         )}
      //       >
      //         <option value="">{t("Region")}</option>
      //         {keys(groupedContentByRegion).map(
      //           (id: string) =>
      //             id != "undefined" && (
      //               <option value={id}>
      //                 {
      //                   useLocalisedContent(
      //                     groupedContentByRegion[id][0].node.frontmatter.region
      //                   )?.title
      //                 }
      //               </option>
      //             )
      //         )}
      //       </Select>
      //     </Box>
      //     <Box
      //       pl={["0px", "4px", "28px"]}
      //       pr={["0px", "2px", "14px"]}
      //       sx={{
      //         direction: dir.isRtl ? "rtl" : "ltr",
      //         flex: ["100%", "25%", "25%"],
      //       }}
      //     >
      //       <Select
      //         name="language"
      //         onChange={handleLanguageChange}
      //         sx={FilterStyling(
      //           state.filters.selectedLocale,
      //           "movement",
      //           fadedColour,
      //           unintrusiveColour,
      //           dir,
      //           false
      //         )}
      //       >
      //         <option value="">{t("Language")}</option>
      //         {keys(groupedContentByLanguage).map(
      //           (id: string) =>
      //             id != "undefined" && (
      //               <option value={id}>
      //                 {languages.find(l => l.isoName == id)?.nativeName}
      //               </option>
      //             )
      //         )}
      //       </Select>
      //     </Box>
      //     <Box
      //       pl={["0px", "6px", "42px"]}
      //       sx={{
      //         direction: dir.isRtl ? "rtl" : "ltr",
      //         flex: ["100%", "25%", "25%"],
      //       }}
      //     >
      //       <Select
      //         name="author"
      //         onChange={handleAuthorChange}
      //         sx={FilterStyling(
      //           state.filters.selectedAuthorId,
      //           "movement",
      //           fadedColour,
      //           unintrusiveColour,
      //           dir,
      //           true
      //         )}
      //       >
      //         <option value="">{t("Author")}</option>
      //         {keys(groupedArticlesByAuthor).map(
      //           (id: string) =>
      //             id != "undefined" && (
      //               <option value={id}>
      //                 {
      //                   groupedArticlesByAuthor[
      //                     id
      //                   ][0].node.frontmatter.authors?.filter(
      //                     author => author?.id == id
      //                   )[0]?.frontmatter.title
      //                 }
      //               </option>
      //             )
      //         )}
      //       </Select>
      //     </Box>
      //   </Flex>
      // }
    >
      <div>
        <SEO
          path={pathname}
          title={"Movement"}
          description={
            "The Movement connects activists around the world to share resources, host workshops, build campaigns, and plan actions."
          }
        />

        <div>
          <MovementPrimaryFeaturedWrapper tileWrapper={tileList[0]} />
          <MovementSecondaryFeaturedBlock tileWrappers={tileList.slice(1, 5)} />
          <MovementAnnouncementBlock edges={validActions} />
          <MovementSecondaryFeaturedBlock tileWrappers={tileList.slice(5)} />
        </div>
      </div>
    </MovementPageContainer>
  )
}

export const pageQuery = graphql`
  query MovementIndexPage {
    actions: allMarkdownRemark(
      filter: {
        fields: { type: { in: ["movement-actions", "movement-articles"] } }
      }
      sort: {
        fields: [frontmatter___publishDate, frontmatter___startDate]
        order: DESC
      }
    ) {
      edges {
        node {
          ...ListFragment
        }
      }
    }
    campaigns: allMarkdownRemark(
      filter: { fields: { type: { eq: "movement-campaigns" } } }
      sort: { fields: [frontmatter___startDate], order: DESC }
    ) {
      edges {
        node {
          ...PrimaryListFragment
        }
      }
    }
    tiles: allMarkdownRemark(
      filter: { frontmatter: { contentId: { eq: "movement-tiles" } } }
    ) {
      edges {
        node {
          frontmatter {
            tiles {
              topic {
                id
                fields {
                  translations {
                    childMarkdownRemark {
                      frontmatter {
                        locale
                        title
                      }
                    }
                  }
                }
              }
              region {
                id
                fields {
                  translations {
                    childMarkdownRemark {
                      frontmatter {
                        locale
                        title
                      }
                    }
                  }
                }
              }
              authors {
                id
                frontmatter {
                  title
                }
              }
              campaignArticle {
                ...PrimaryListFragment
              }
              translations {
                locale
                manualLink
                title
              }
              manualImage {
                childImageSharp {
                  fluid(maxWidth: 1250, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default MovementIndexPage
